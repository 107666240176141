import React from 'react';
import { defaultContact } from '../utils/contact';

const Email = () => {
  return (
    <a
      href={`mailto:${defaultContact.email}`}
      target="_blank"
      rel="noreferrer"
      className="footer-text"
    >
      {defaultContact.email}
    </a>
  );
};

export default Email;

import React from 'react';
import './style.scss';

const ReusableHero = ({ heroText }) => {
  return (
    <section className="container has-text-centered reusable-hero">
      <div>
        <h4 className="subtitle is-size-4 is-size-6-mobile">
          {heroText.first}
        </h4>
        <h4 className="subtitle is-size-4 is-size-6-mobile">
          {heroText.second}
        </h4>
      </div>
    </section>
  );
};

export default ReusableHero;

import React from 'react';
import logo from '../../images/logo-final.png';
import Email from '../Email';
import { Link } from 'gatsby';
import { defaultContact, social } from '../../utils/contact';
import SocialIcons from '../SocialIcons';
import moment from 'moment';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../style.scss';
import './footer.scss';

const lastYear = moment()
  .subtract(1, 'year')
  .year();
// const nextYear = moment()
//   .add(1, 'year')
//   .year();

const FooterMenu = () => {
  return (
    <footer className="footer has-text-centered-mobile">
      <div className="columns is-centered has-text-centered pt-4">
        <div className="column is-three-quarters">
          <p>
            iDECIDE is funded by a Cooperative Agreement to MGH from the
            Massachusetts Department of Public Health, Office of Youth & Young
            Adult Services’ federal award by the Substance Abuse and Mental
            Health Services Administration.
          </p>
          <br />
        </div>
      </div>
      <div className="columns">
        <div className="column is-0" />
        <div className="column is-3">
          <div className="column">
            <img
              className="logo-image-footer"
              src={logo}
              alt={logo.toString()}
            />
          </div>
        </div>
        <div className="column is-4">
          <div className="column">
            <p className="is-size-5 has-text-weight-semibold">Contact Info</p>
            <div className="mt-2">
              <p className="is-size-6">101 Merrimac St., Suite 320</p>
              <p className="is-size-6">Boston, MA 02114</p>
            </div>
          </div>
          <div className="column">
            <span className="icon pl-1">
              <i className="fas fa-phone-square-alt fa-lg mr-3 is-size-7"></i>
            </span>
            {defaultContact.phone}
            <p>
              <span className="icon pl-1">
                <i className="far fa-envelope fa-lg mr-3 is-size-7"></i>
              </span>
              <Email />
            </p>
          </div>
          <div className="column" style={{ paddingLeft: '.4rem' }}>
            <SocialIcons social={social} />
          </div>
        </div>
        <div className="column is-1" />
        <div className="column">
          <div className="column">
            <div className="pb-2">
              <Link to="/about/#FAQ" className="footer-text">
                FAQ
              </Link>
            </div>
            <div className="pb-2">
              <Link to="/references" className="footer-text">
                References
              </Link>
            </div>
            <div className="pb-2">
              <a href="https://lms.idecidemyfuture.org" className="footer-text">
                Log in
              </a>
            </div>

            <div className="pb-2">
              <a
                href="https://redcap.link/bulletin.training"
                className="footer-text"
              >
                Sign Up Here to Learn More About iDECIDE
              </a>
            </div>
            <div className="pb-2">
              <Link to="/accessibility" className="footer-text">
                Accessibility
              </Link>
            </div>
            <div className="pb-2">
              <Link to="/land-acknowledgement" className="footer-text">
                Land Acknowledgement
              </Link>
            </div>
          </div>
        </div>
        <div className="column is-0" />
      </div>
      <p className="copyright is-size-7 mb-2">
        Copyright © {lastYear} The General Hospital Corporation. All rights
        reserved.
      </p>
    </footer>
  );
};

export default FooterMenu;

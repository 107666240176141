import React from 'react';
import ReusableHeader from '../reusableHeader';
import ReusableHero from '../reusableHero';

const ReusableTop = ({ headerText, heroText, heroBg, heroFont }) => {
  return (
    <>
      {headerText && (
        <ReusableHeader
          headerText={headerText}
          heroBg={heroBg}
          heroFont={heroFont}
        />
      )}
      {heroText && <ReusableHero heroText={heroText} />}
    </>
  );
};

export default ReusableTop;

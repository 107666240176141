import React from 'react';
import { Link } from 'gatsby';
import { defaultContact, social } from '../../utils/contact';
import SocialIcons from '../SocialIcons';
import '@fortawesome/fontawesome-free/css/all.min.css';

const linkStyle = 'is-size-6 is-primary has-text-weight-semibold level-item';

const TopLevel = () => {
  return (
    <div className="level is-hidden-mobile has-background-white my-2">
      <div className="level-left ml-5">
        <div className="level-item">
          <p className="is-size-6">Your Brain. Your Future. Your Choice.</p>
        </div>
      </div>
      <div className="level-right mr-2">
        <p className="level-item">
          <span className="icon has-text-primary">
            <i className={`far fa-envelope fa-lg is-size-7`}></i>
          </span>
          <a
            href={`mailto:${defaultContact.email}`}
            target="_blank"
            rel="noreferrer"
            className="is-size-6 has-text-black"
          >
            {defaultContact.email}
          </a>
        </p>
        <p className={`${linkStyle} ml-5`}>
          <a
            href={'https://giving.massgeneral.org/donate/school-based/'}
            target="_blank"
            rel="noreferrer"
          >
            Donate
          </a>
        </p>
        <p className={`${linkStyle} ml-2`}>
          <a href="https://redcap.link/bulletin.training">
            Sign Up Here to Learn More About iDECIDE
          </a>
        </p>
        <div className="social-icons-tablet">
          <SocialIcons social={social} />
        </div>
      </div>
    </div>
  );
};

export default TopLevel;

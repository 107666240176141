import React from 'react';

const SocialIcons = ({ social }) => {
  return social.map((org, i) => {
    const { link, icon, alt } = org;
    return (
      <a key={i} href={`${link}`} name={alt} target="_blank" rel="noreferrer">
        <span className="icon is-medium has-text-primary">
          <span className="fa-lg">
            <i className={`${icon}`}></i>
          </span>
        </span>
      </a>
    );
  });
};

export default SocialIcons;

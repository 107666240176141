export const defaultContact = {
  email: 'iDECIDE@mgh.harvard.edu',
  phone: '617-643-1771',
};

export const social = [
  {
    icon: 'fab fa-facebook',
    link: 'https://fb.me/projectiDECIDE',
    alt: 'facebook link',
  },
  {
    icon: 'fab fa-instagram',
    link: 'https://www.instagram.com/project_idecide/',
    alt: 'instagram link',
  },
  {
    icon: 'fab fa-twitter',
    link: 'https://twitter.com/idecideteam',
    alt: 'twitter link',
  },
  {
    icon: 'fab fa-linkedin',
    link: 'https://www.linkedin.com/company/projectidecide',
    alt: 'linkedin link',
  },
];

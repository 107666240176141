import React from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from 'react-helmet';
import ReusableTop from './resuableHeroHeader/index';
import './style.scss';
import darkIcon from '../images/logo-neg.ico';
import lightIcon from '../images/logo-final.ico';

const Layout = ({ children, headerText, heroText, heroBg, heroFont }) => {
  return (
    <div className="wrapper">
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <title>iDECIDE</title>
        <base target="_blank" />
        <link rel="canonical" href="https://www.idecidemyfuture.org" />
        <link
          rel="icon"
          href={lightIcon}
          media="(prefers-color-scheme:no-preference)"
        />
        <link rel="icon" href={darkIcon} media="(prefers-color-scheme:dark)" />
        <link
          rel="icon"
          href={lightIcon}
          media="(prefers-color-scheme:light)"
        />
      </Helmet>
      <Header />
      <div className="top-body">
        {headerText && (
          <ReusableTop
            headerText={headerText}
            heroText={heroText}
            heroBg={heroBg}
            heroFont={heroFont}
          />
        )}
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;

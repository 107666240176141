import React from 'react';
import './style.scss';

const ReusableHeader = ({ headerText, heroBg, heroFont }) => {
  return (
    <div className="mt-6 header-bg hero has-text-centered">
      <div className={`hero-body hero-bg-img ${heroBg}`}>
        <h1
          className={`title header-text is-size-1 mobile-header-top ${heroFont}`}
        >
          {headerText}
        </h1>
      </div>
    </div>
  );
};

export default ReusableHeader;

import React, { useState } from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo-final.png';
import TopLevel from './TopLevel';
import '../style.scss';
import './nav.scss';

const links = [
  {
    name: 'About',
    to: '/about',
    subMenu: [
      'Introduction',
      'Mission',
      'Format',
      'Research',
      'Leadership',
      'FAQ',
    ],
  },
  {
    name: 'Substance Use 101',
    to: '/one-oh-one',
    subMenu: [
      'Rates of Teen Use',
      'Teen Brain Development',
      'Substance Use & the Brain',
      'Health Effects',
      'Signs & Symptoms of Addiction',
      'Industry Influence',
      'Better Addressing Substance Use as a Society',
    ],
  },
  {
    name: 'Facilitators',
    to: '/facilitators',
    subMenu: [
      'Introduction',
      'Schools Enrolled in iDECIDE',
      'Become an iDECIDE Facilitator',
      'Additional Training Opportunities',
      'Log in',
    ],
  },
  {
    name: 'Resources',
    to: '/resources',
    // subMenu: [
    //   'Teens',
    //   'Family',
    //   'Evidence-Based Practice & Trainings',
    //   'Education',
    //   'Help',
    //   'Massachusetts Statewide Resources',
    //   'Massachusetts Regional Resources',
    // ],
  },
  {
    name: 'News',
    to: '/news',
    subMenu: [
      'Job Openings',
      'Announcements',
      'In the News',
      'Publications',
      'Social Media Spotlight',
    ],
  },
];

const semiBold = 'has-text-weight-semibold';
const navbarItem = `navbar-item ${semiBold} is-size-8 pop`;

const createLinks = linksArr => {
  return linksArr.map((link, i) => {
    const { subMenu, to, name } = link;
    return (
      <div className="navbar-item has-dropdown is-hoverable is-size-5" key={i}>
        <Link className={`link-nav navbar-item ${semiBold}`} to={to}>
          {name}
        </Link>
        {subMenu && (
          <div
            className="nav-wide navbar-dropdown is-hidden-mobile tablet-hide is-boxed"
            style={{ overflow: 'hidden' }}
          >
            {subMenu.map((sub, i) => {
              if (sub === 'Log in') {
                return (
                  <a
                    href="https://lms.idecidemyfuture.org"
                    className={navbarItem}
                    key={i}
                  >
                    Log in
                  </a>
                );
              } else
                return (
                  <Link className={navbarItem} key={i} to={`${to}/#${sub}`}>
                    {sub}
                  </Link>
                );
            })}
          </div>
        )}
      </div>
    );
  });
};

const NavMenu = () => {
  const [burgerActive, setBurgerActive] = useState(false);

  return (
    <div className={'has-background-white custom-is-fixed-top'}>
      <TopLevel />
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className={`navbar-brand navbar-more ${semiBold}`}>
          <Link className="navbar-item" to="/">
            <img className="logo" alt="logo" src={logo} />
          </Link>
          <button
            onClick={() => setBurgerActive(!burgerActive)}
            className={`button is-link is-size-5 ${semiBold} navbar-burger ${burgerActive &&
              'is-active'}`}
            aria-label="menu"
            aria-expanded="false"
          >
            <span
              aria-hidden="true"
              className={`${burgerActive && 'is-active'} burgers`}
            ></span>
            <span
              aria-hidden="true"
              className={`${burgerActive && 'is-active'} burgers`}
            ></span>
            <span
              aria-hidden="true"
              className={`${burgerActive && 'is-active'} burgers`}
            ></span>
          </button>
        </div>
        <div className={`navbar-menu ${burgerActive ? 'is-active' : null}`}>
          <div className="navbar-start"></div>
          <div className="navbar-end">
            {createLinks(links)}
            <div className="navbar-item">
              <div className="buttons">
                <a
                  href="https://lms.idecidemyfuture.org"
                  className={`button is-light ${semiBold} has-text-white has-background-primary`}
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavMenu;
